@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
  --content-blue: #0C46A2;
  --transparent_blue: #0c45a2ab;
  --content-blue-light:#27ABDE;
}
body{
  overflow-x: hidden;
}

header{
  width: 100vw;
  height: 80px;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.929);
  backdrop-filter: blur(5px);
}

.logo{
  animation: rotate 10s infinite ease;
}

.blue_text{
  color: var(--content-blue);
}

.blue_text_light{
  color: var(--content-blue-light);
}


.content_blue_text{
  background-image: radial-gradient(circle, var(--content-blue), var(--content-blue-light));
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  background-size: 500% 100%; 
  background-clip: text;
  transition: .5s ease-in-out;
  text-transform: uppercase;
  animation: move-bg 5s linear infinite alternate; /* Animation properties */
}

@keyframes move-bg {
  from { background-position: 0 0; }
  to { background-position: -200% 0; } /* Move 200% to cover entire text */
}

@keyframes rotate {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg)
  }
}

.nav__link{
  transition: .3s ease-in-out;
  font-weight: 600;
}
.mobile__link:hover,.nav__link:hover{
  background-color: var(--primary_blue);
  color: var(--content-blue);
}

.menu{
  width: 50px;
  cursor: pointer;
}

.menu__btn{
  background-color: var(--content-blue);
  height: 2px;
  width: 30px;
  position: relative;
  transition: .3s ease-in-out;
}

.menu__btn::after, .menu__btn::before{
  content:"";
  height: inherit;
  width: inherit;
  background-color: var(--content-blue);
  position: absolute;
  transition: .3s ease-in-out;

}
.menu__btn::before{
  transform: translateY(-7px);
  
}
.menu__btn::after{
  transform: translateY(7px);
  
}
.menu__btn.active{
  background-color: transparent;
}
.menu__btn.active::after{
  transform: rotate(135deg);
}
.menu__btn.active::before{
  transform: rotate(45deg);
}


.mobile__menu{
  position: absolute;
  height: calc(100vh - 80px);
  top: 80px;
  left: -100%;
  width: 100%;
  transition: .5s ease-in-out;
  opacity: 0;
}
.mobile__menu.active{
  left: 0;
  opacity: 1;
}

.mobile__link{
  background-color: rgba(255, 255, 255, 0.929);
  transform: translateX(-20px);
  transition: .2s ease-in-out;
  z-index: 999;
  backdrop-filter: blur(5px);
  
}


.mobile__link.active{
  opacity: 1;
  transform: translateX(0);
}
.mobile__link.active:nth-child(1){
  transition-delay: .2s;
}
.mobile__link.active:nth-child(2){
  transition-delay: .3s;
}
.mobile__link.active:nth-child(3){
  transition-delay: .4s;
}
.mobile__link.active:nth-child(4){
  transition-delay: .5s;
}
.mobile__link.active:nth-child(5){
  transition-delay: .6s;
}

.mobile__link.in-active{
  opacity: 0;
  transition: .3s ease-in-out;
  transform: translateX(-20px);
}

.mobile__link.in-active:nth-child(1){
  transition-delay: .5s;
}
.mobile__link.in-active:nth-child(2){
  transition-delay: .4s;
}
.mobile__link.in-active:nth-child(3){
  transition-delay: .3s;
}
.mobile__link.in-active:nth-child(4){
  transition-delay: .2s;
}
.mobile__link.in-active:nth-child(5){
  transition-delay: .1s;
}

/* HERO SECTION */

.hero{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.493)) , url(./assets/images/dr_ebun.jpg);
  min-height: calc(100vh - 80px);
  background-size: cover;
  background-position: center;
}

.service_btn{
  border: 1px solid var(--content-blue-light);
  background-color: white;
  color: var(--content-blue);
  transition: .3s ease-in-out;
}

.service_btn:hover{
  background-color: rgba(255, 255, 255, 0.733);
  color: var(--content-blue);
  border: 1px solid var(--content-blue);
  border-radius: 10px;
}

.hero_head>h1 {
  opacity: 0;
  animation: show 1s forwards;
  transform: translateY(15px);
  animation-delay: 1s;
}

.hero_head>p:nth-child(1) , .page_banner>h1{
  opacity: 0;
  animation: show 1s forwards;
  transform: translateY(15px);
  animation-delay: .5s;
}
.hero_head .hero_intro_p{
  opacity: 0;
  animation: show 1s forwards;
  transform: translateY(15px);
  animation-delay: 1.5s;
}


@keyframes show {
  0%{
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}


/* Care Section */

.care_section{
  padding: 4rem 0 ;
  background: radial-gradient(#4e46e434 15%, transparent 16%), linear-gradient(45deg, transparent 49%, #4e46e452 49% 51%, transparent 51%), linear-gradient(-45deg, transparent 49%, #4e46e466 49% 51%, transparent 51%);
  background-size: 5em 5em;
  background-color: var(--transparent_blue);
    
}

.card{
  background-color: white;
  border-radius: 10px;
  transition: .3s ease-in-out;
}
.card:hover{
  box-shadow: 3px 3px 20px black;
  cursor: pointer;
  transform: translateY(-5px);
}


/* A visit to the doctor section */
.doc_article_img{
  transition: .3s ease-in-out;
}
.doc_article_img:hover{
  box-shadow: blue 2px 2px 2px 0px inset, rgb(255, 255, 255) 10px -10px 0px -3px, var(--content-blue-light) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, var(--content-blue) 20px -20px, rgb(255, 255, 255) 30px -30px 
}

.call_to_action_btn{
  border: 1px solid var(--content-blue);
  color: white;
  background-color: var(--content-blue);
  transition: .3s ease-in-out;
}

.call_to_action_btn:hover{
  border: 1px solid var(--content-blue-light);
  background-color: var(--content-blue-light);
}


/* FOOTER SECTION */
.footer_paragraph{
  background-color: var(--content-blue);
  
}




/* ABOUT PAGE */

.page_banner{
  
  min-height: 50vh;
  background-size: cover;
  background-position: center;
}

.about_header{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.493)) , url(./assets/images/about_bg.jpg);
}


.page_banner h1{
  color: white;
  text-transform: uppercase;
}


/* CAREERS PAGE */

.career_header{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.493)) , url(./assets/images/careers.jpg);
  background-attachment: scroll;
}


/* CONTACT Page */

.contact_header{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.493)) , url(./assets/images/contact.jpg);
  background-attachment: scroll;
}

.submitBtn{
  background-color: var(--content-blue);
  transition: .3s ease-in-out;

}

.submitBtn:hover{
  background-color: var(--content-blue-light);
}